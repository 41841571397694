/*global window, document,jQuery, CountUp*/
jQuery(document).ready(function ($) {
    'use strict';

    var options = {
        useEasing: true,
        separator: '.',
        decimal: ','
    };

    var counter = $('.outletCounter-number');

    if (counter.length) {
        // Detect when a counter element is scrolled into view only every 300ms for performance issues
        $(window).on('scroll.MaxServ.detectElement', Foundation.util.throttle(detectElement, 300));
        // Call detectElement to start the animation in case there are countUp items in the viewport after pageload
        detectElement();
    } else {
        // Don't execute the rest of the script when no counter was found
        return false;
    }

    function detectElement() {
        var window_height = $(window).height(),
            window_top_position = $(window).scrollTop(),
            window_bottom_position = (window_top_position + window_height);

        counter.each(function () {
            var element = $(this),
                element_height = element.outerHeight(),
                element_top_position = element.offset().top,
                element_bottom_position = (element_top_position + element_height),
                element_attr = element.attr('data-count-up');
            if (element_attr > 0 && (element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position) && (!element.hasClass('is-fired'))) {

                var numArea;
                if (element_attr.indexOf('.') === -1) {
                    numArea = new CountUp($(this)[0], 0, element_attr, 0, 4, options);
                }
                else {
                    numArea = new CountUp($(this)[0], 0, element_attr, 2, 4, options);
                }
                element.addClass('is-fired');

                setTimeout(function () {
                    numArea.start();
                }, 200);
            }
        });
    }
});
