/*global document, jQuery */
jQuery(document).ready(function ($) {
    'use strict';

    // These radios and checkboxes have a alternate checkbox look and are used in the kitchen wish list
    // Default radio and checkbox functionality should be defined in InputHandler.js instead of this file.
    var kwlRadioCheckWrapper = $('.kwl-option-streamer'),
        kwlRadioCheckLabel = $('.kwl-option-streamer label'),
        kwlSubOptionsWrapper = $('.kwl-option--subOptions'),
        isScrolling = false;

    if ($('.kwl').length === 0) {
        return;
    }

    kwlRadioCheckWrapper.find('.customRadio').addClass('customInput--largeCheckbox');
    kwlRadioCheckWrapper.find('.customCheckbox').addClass('customInput--largeCheckbox');
    kwlSubOptionsWrapper.find('.kwl-option-description').find('.customCheckbox').addClass('customInput--largeCheckbox js-subs');

    $('.kwl .customInput--largeCheckbox').add(kwlRadioCheckLabel).on('click', function () {

        if ($(this).parent().find('input[type="radio"], input[type="checkbox"]')) {

            if ($(this).siblings('input[type="radio"]').length > 0) {
                $(this).parentsUntil('.kwl-formBody').find('.kwl-option').removeClass('active');
            }

            $(this).parents('.kwl-option').toggleClass('active');
        }
    });

    // Because there are some page reloads in the kitchen wish list when the user navigates through the steps,
    // with this function we add back the active classes and required field of certain elements
    $('.kwl-option').each(function () {
        if ($(this).find('input[type="radio"], input[type="checkbox"]').is(':checked')) {
            $(this).addClass('active');
            $(this).find('.kwl-additionalQuestion').removeClass('kwl-additionalQuestion--hidden');
        }

        if ($(this).hasClass('active') && $(this).find('.kwl-additionalQuestion').length) {
            $(this).parents('.is-active').find('input').prop("required", false);
            $(this).find('.kwl-additionalQuestion').find('input').prop("required", true);
        }
    });


    $(document).on('click', '', function () {
        $(".kwl-option--subOptions").each(function () {
            var checked = $(this).find(".kwl-option-description :checked").size() > 0,
                radio = $(this).find(".streamer :checkbox");
            radio.prop('checked', checked);
        });
    });

    // Toggle additional question if the user chooses a kwl option with an additional question
    $('.kwl-hasAdditionalQuestion label, .kwl-hasAdditionalQuestion .customRadio').on('click', function () {
        $(this).parents('.is-active').find('.kwl-additionalQuestion').addClass('kwl-additionalQuestion--hidden');
        $(this).parents('.kwl-option').find('.kwl-additionalQuestion').removeClass('kwl-additionalQuestion--hidden').addClass('kwl-additionalQuestion--animated');

        // Remove all required attributes for input fields in the current view, make input fields required in the active option
        $(this).parents('.is-active').find('input').prop("required", false);
        $(this).parents('.kwl-option').find('input').prop("required", true);

        if (isScrolling === false) {
            $(this).parents('.is-active').find('.kwl-additionalQuestion').removeClass('kwl-additionalQuestion--animated');

            $('html, body').animate({
                scrollTop: $(this).parents('.kwl-option').find('.kwl-additionalQuestion').offset().top
            }, 1000, function () {
                isScrolling = false;
            });
            isScrolling = true;
        }
    });
});
