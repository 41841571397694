/*global Foundation, window, document, jQuery*/
/*jslint nomen: true*/
jQuery(document).ready(function ($) {
    'use strict';

    var getErrorsWrapperElement,
        iconPath =  window.svgPath || '/typo3conf/ext/site_keukenconcurrent/Resources/Public/Icons/Sprite/Dest/sprite.svg',
        successIcon,
        errorIcon;

    if(iconPath) {
        successIcon = '<svg class="icon icon--validation parsley-icon-success"><use href="' + iconPath + '#success' + '"></use></svg>';
        errorIcon = '<svg class="icon icon--validation parsley-icon-error"><use href="' + iconPath + '#error' + '"></use></svg>';
    }

    getErrorsWrapperElement = function (parsleyFieldInstance) {
        var $errorsWrapper = null;

        if ((parsleyFieldInstance._ui === undefined || parsleyFieldInstance._ui === null)
            && typeof parsleyFieldInstance._buildUI === 'function'
        ) {
            // If there is no _ui instance yet, build it.
            parsleyFieldInstance._buildUI();
        }

        // check if the options object contains an $errorsWrapper property which is the template for the list to add to the DOM
        if (parsleyFieldInstance.$element !== undefined
            && parsleyFieldInstance.$element !== null
            && parsleyFieldInstance._ui !== undefined
            && parsleyFieldInstance._ui !== null
            && parsleyFieldInstance._ui.$errorsWrapper !== undefined
            && parsleyFieldInstance._ui.$errorsWrapper !== null
            && parsleyFieldInstance._ui.errorsWrapperId !== undefined
            && parsleyFieldInstance._ui.errorsWrapperId !== null
        ) {
            // before adding the element check if it's not already available in the DOM
            if ($('#' + parsleyFieldInstance._ui.errorsWrapperId).length === 0) {
                parsleyFieldInstance._ui.$errorsWrapper.insertBefore(parsleyFieldInstance.$element);
            }

            $errorsWrapper = parsleyFieldInstance._ui.$errorsWrapper;
        }

        return $errorsWrapper;
    };

    window.Parsley.on('field:init', function () {
        var parsleyFieldInstance = this;

        // check if the field is required before adding an validation placeholder
        if (parsleyFieldInstance.$element !== undefined && parsleyFieldInstance.$element !== null
            && parsleyFieldInstance.$element.prop('required')
            && (parsleyFieldInstance.$element.prop('type') !== 'radio'
                && parsleyFieldInstance.$element.prop('type') !== 'checkbox'
            )
        ) {
            // delay the actual function since the UI is not completely ready
            window.setTimeout(function () {
                var $errorsWrapper = getErrorsWrapperElement(parsleyFieldInstance);

                if ($errorsWrapper !== null) {
                    $errorsWrapper.addClass('parsley-errors-list-empty');

                    // add an empty <li /> to ensure a valid DOM structure
                    if ($errorsWrapper.find('.parsley-item-success').length === 0) {
                        $errorsWrapper.append('<li class="parsley-item-empty">&nbsp;</li>');
                    }
                }
            }, 100);
        }
    });

    window.Parsley.on('field:success', function () {
        var $errorsWrapper = getErrorsWrapperElement(this);

        if ($errorsWrapper !== null) {
            // remove the placeholder element
            $errorsWrapper.removeClass('parsley-errors-list-empty');
            $errorsWrapper.parent().find('.parsley-item-empty, .parsley-item-error').remove();

            $errorsWrapper.addClass('parsley-errors-list-success');

            // add an empty <li /> to ensure a valid DOM structure
            if ($errorsWrapper.parent().find('.parsley-item-success').length === 0 && successIcon !== undefined) {
                $errorsWrapper.after('<div class="parsley-item-success">' + successIcon + '</div>');
            }

            if ($errorsWrapper.parents('.powermail_fieldwrap_check').length) {
                $errorsWrapper.parents('.powermail_fieldwrap_check').removeClass('powermail_fieldwrap_check--error').addClass('powermail_fieldwrap_check--success');
            }

            if ($errorsWrapper.parents('.powermail_fieldwrap_radio').length) {
                $errorsWrapper.parents('.powermail_fieldwrap_radio').removeClass('powermail_fieldwrap_radio--error').addClass('powermail_fieldwrap_radio--success');
            }
        }
    });

    window.Parsley.on('field:error', function () {
        var $errorsWrapper = getErrorsWrapperElement(this);

        if ($errorsWrapper !== null) {
            // remove the placeholder elements and/or possible success messages
            $errorsWrapper.removeClass('parsley-errors-list-empty parsley-errors-list-success');
            $errorsWrapper.parent().find('.parsley-item-empty, .parsley-item-success, .parsley-item-error').remove();
        }

        // add an empty <li /> to ensure a valid DOM structure
        if ($errorsWrapper.parent().find('.parsley-item-error').length === 0 && errorIcon !== undefined) {
            $errorsWrapper.after('<div class="parsley-item-error">' + errorIcon + '</div>');
        }

        if ($errorsWrapper.parents('.powermail_fieldwrap_check').length) {
            $errorsWrapper.parents('.powermail_fieldwrap_check').removeClass('powermail_fieldwrap_check--success').addClass('powermail_fieldwrap_check--error');
        }

        if ($errorsWrapper.parents('.powermail_fieldwrap_radio').length) {
            $errorsWrapper.parents('.powermail_fieldwrap_radio').removeClass('powermail_fieldwrap_radio--success').addClass('powermail_fieldwrap_radio--error');
        }
    });
});
