/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';
    var showRoomFilter = $('.lookup-facets-frame--showroom_filters'),
        showRoomFilterMenu = showRoomFilter.find('header'),
        selectBoxes = showRoomFilter.find('select option[selected="selected"]');

    // The filters are shown when the user has selected a value
    selectBoxes.parents(showRoomFilter).find(showRoomFilterMenu).addClass('active');

    showRoomFilterMenu.on('click',function () {
        $(this).toggleClass('active');
    });
});
