const accordionToggles = document.querySelectorAll('.js-accordion-toggle');

for (var i = 0; i < accordionToggles.length; i++) {
    accordionToggles[i].addEventListener('click', function (evt) {
        const accordion = evt.target.closest('.js-accordionTextImage');

        if (accordion.length !== 0) {
            accordion.classList.toggle('accordionTextImage--active');
        }
    });
}
