/*global jQuery*/

jQuery(function ($) {
    'use strict';

    $('.multiFormPanel').each(function () {
        var $element = $(this),
            $forms = $element.find('.multiFormPanel-forms [id^="c"]'),
            $selectContainer = $element.find('.multiFormPanel-select'),
            $selectBox = $selectContainer.find('select');

        // for each form in the container create an option for the select
        $forms.each(function (index) {
            var $form = $(this),
                $option = $('<option />');

            $form.wrap('<div class="powermail"></div>');

            // hide every form except for the first
            if (index !== 0) {
                $form.parents('.powermail').addClass('hide');
            }

            // disable input for every form as long there's no subject selected
            $form.find('fieldset').prop('disabled', true);
            $form.parents('.multiFormPanel-forms').addClass('faded');

            $option.attr({
                value: $form.attr('id')
            });

            // use the value of the first heading as label for the option
            $option.text(
                $form.find('.mg-heading').text()
            );

            $selectBox.append($option);
        });

        // enable the select field no we are sure JS is enabled
        $selectContainer.removeClass('hide');

        $selectBox.on('change', function () {
            var selectedOption = $selectBox.val(),
                $form;

            // hide and disable all forms
            $forms.parents('.powermail').addClass('hide');
            $forms.find('fieldset').prop('disabled', true);

            if (selectedOption.charAt(0) === 'c') {
                $form = $element.find('#' + selectedOption);
            } else {
                $('.multiFormPanel-forms').addClass('faded');
            }

            // if a form is selected display and enable it again,
            // otherwise display the first form in disabled state restoring the initial view
            if ($form !== undefined) {
                $form.parents('.powermail').removeClass('hide');
                $form.find('fieldset').prop('disabled', false);
                $form.parents('.multiFormPanel-forms').removeClass('faded');
            } else {
                $($forms.get(0)).parents('.powermail').removeClass('hide');
            }
        });

        // if a form is submitted a confirmation might be displayed, in that case switch to this "tab"
        $forms.find('.powermail_create').each(function () {
            var $activeForm = $(this),
                $contentElement = $activeForm.parentsUntil('[id^="c"]').parent('[id^="c"]'),
                identifier = $contentElement.attr('id');

            // change the current selection and trigger an event to modify the display
            $selectBox.val(identifier).trigger('change');
        });
    });
});
