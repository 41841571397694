document.addEventListener('DOMContentLoaded', () => {
    'use strict';

    const stickyElement = document.querySelector('.js-stickyElement');

    if (!stickyElement) {
        return;
    }

    let prevScrollpos = window.pageYOffset;

    const stickyHandler = () => {
        const currentScrollPos = window.pageYOffset;

        if (prevScrollpos > currentScrollPos) {
            stickyElement.classList.remove('is-up');
            stickyElement.classList.add('is-active');
        } else {
            stickyElement.classList.remove('is-active');
            stickyElement.classList.add('is-up');
        }

        if (currentScrollPos <= stickyElement.clientHeight) {
            stickyElement.classList.remove('is-up');
        }
        prevScrollpos = currentScrollPos;
    }

    const throttledStickyHandler = () => {
        let timeout;

        return () => {
            clearTimeout(timeout);
            timeout = setTimeout(stickyHandler, 100);
        };
    };

    window.addEventListener('scroll', throttledStickyHandler());
});
