/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var forms = $('.powermail_progress'),
        maxValuePercentage = 100;

    function update($form) {
        var percentage = 25,
            bar = $form.data('progressBar');
        $form.find('input:required').each(function () {
            if (!$(this).hasClass('parsley-error') && $(this).val()) {
                if (percentage < maxValuePercentage) {
                    percentage = percentage + $form.data('percentage');
                }
            }
        });

        bar.find('.powermail-progressBar-bar').css('width', percentage + '%').attr('data-progress-bar-state', Math.round(percentage) + '%');
    }

    forms.each(function () {
        var inputs = $(this).find(':required');
        $(this).data({
            percentageBar: 25,
            percentage: 75 / inputs.length,
            progressBar: $(this).find('.powermail-progressBar')
        });

        inputs.data('form', $(this)).on('change', function () {
            update($(this).data('form'));
        });
    });

});
