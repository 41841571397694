/*global jQuery,document*/
jQuery(document).ready(function ($) {
    'use strict';

    var toggleOpenDays = $('.js-toggleOpenDays');

    if(toggleOpenDays.length) {
        toggleOpenDays.on('click',function (e) {
            e.preventDefault();

            $(this).parents('.js-openDays').toggleClass('is-active');
        });

        toggleOpenDays.first().parents('.js-openDays').addClass('is-active');
    }
});
