/*global window*/
(function () {
    'use strict';

    window.MaxServ = window.MaxServ || {};

    // RequestAnimationFrame fallback for prefixes + old browsers
    window.MaxServ.requestAnimationFrame = window.requestAnimationFrame
        || window.webkitRequestAnimationFrame
        || window.mozRequestAnimationFrame
        || function (callback) {
            return window.setTimeout(callback, 1000 / 60);
        };

    window.MaxServ.transitionEndEventName = (document.documentElement.style.hasOwnProperty('WebkitTransition')) ? 'webkitTransitionEnd' : 'transitionend';

    window.MaxServ.focusableElements = 'a, input, select, textarea, button, iframe';

    window.MaxServ.setTransformTranslate = function (element, x, y) {
        element.style.webkitTransform = 'translate(' + x + 'px, ' + y + 'px)';
        element.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
    };
}());
