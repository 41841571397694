/*global document, jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var faqAccordionItem = $('.faqNavigation-submenu').find('li a'),
        faqAccordion = $('.faqAccordion');

    faqAccordionItem.on('click',function (e) {
        // Every faqAccordionItem has a class that is related to some content in the accordion,
        // That content in the accordion has a ID that starts with #p followed by a number (the class name from faqAccordionItem)
        var currentTarget = '#p' + $(this).attr('class');
        e.preventDefault();
        faqAccordion.find(currentTarget).prev().trigger('click');
    });
});
