/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    $('.sidebar-dropdown-title').on('click',function () {
        $(this).toggleClass('active');
        $(this).next().toggleClass('active');
        $(this).parentsUntil('.sidebar-dropdown').parent().trigger('MaxServ.PriceSlider.Init');
    });

    $('.sidebar-dropdown-filter-title').on('click',function () {
        $(this).toggleClass('active');
        $(this).parent().next().toggleClass('active');
        $(this).parentsUntil('.sidebar-dropdown').parent().trigger('MaxServ.PriceSlider.Init');
    });
});
