/*global window, document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    var url = window.location.href,
        $selectFieldOption = $('.powermail_select[name="tx_powermail_pi1\\[field\\]\\[prefilledproductid\\]"] option'),
        productID;

    if (url.indexOf('prefilledproductid=') > -1) {
        productID = url.split('prefilledproductid=')[1].match(/\d+/);

        if (productID !== null) {
            productID = productID[0];

            $selectFieldOption.each(function () {
                if ($(this).val() === productID) {
                    $(this).parents('.powermail_select').val(productID);
                    $(this).parents('.row').first().addClass('hide');
                }
            });
        }
    }
});
