/*global document, event, jQuery */
jQuery(document).ready(function ($) {
    'use strict';

    var selectField = $('.powermail_select'),
        radioWrapper = $('.powermail_radio_outer'),
        combined = $('.powermail-customInput'),
        checkbox = $('input[type="checkbox"]'),
        checkboxLabels = checkbox.parent().find('label'),
        radiobtn = $('input[type="radio"]'),
        radioLabels = radiobtn.parent().find('label').not('.bptMultipleItemGrid-item-label, .bptItemList-item-label');

    if (!radioLabels.next().hasClass('customRadio')) {
        $('<a href="#" class="customRadio js-customInput" tabindex="-1"></a> ').insertAfter(radioLabels);
    }

    if (!checkboxLabels.next().hasClass('customCheckbox')) {
        $('<a href="#" class="customCheckbox js-customCheckbox js-customInput" tabindex="1"></a> ').insertAfter(checkboxLabels);
    }

    $(document).on('click', ".js-customInput, .kwl-option--subOptions-label", function (e) {
        e.preventDefault();

        var input = $(this).parent().find('input[type="radio"], input[type="checkbox"]');
        input.trigger('click');
    });

    combined.on('keypress', function (e) {
        if (e.which === 32) {
            e.preventDefault();
            $(this).trigger('click');
        }
    });

    selectField.each(function () {
        var selectText = $(this).parents('.customSelect').attr('data-select-text');

        if(selectText.length) {
            $(this).prepend('<option disabled selected value></option>');
            $(this).find('option').first().text(selectText);
        }
    });
});
