/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    $('.faq').each(function () {

        var faqId = "#" + this.id,
            faqToggleSelector = ".faq__toggle",
            faqGroupSelector = ".faq__group",
            faqListTitleSelector = ".faq__list-title",
            faqListItemSelector = ".faq__list-item",
            activeClass = "is-active"

        $(this).find(faqToggleSelector).on('click', function () {
            var selector = $(this).data("faq-toggle");

            if (selector) {
                $(faqId).find(faqToggleSelector).removeClass(activeClass).attr('aria-expanded', false);
                $(faqId).find(faqGroupSelector).removeClass(activeClass);
                $(this).addClass(activeClass).attr('aria-expanded', true);
                $(faqId).find('[data-faq-group="'+ selector +'"]').addClass(activeClass);
            }

        });

        $(this).find(faqListTitleSelector).on("click", function () {
            if ($(this).parent(faqListItemSelector).hasClass(activeClass)) {
                $(this).parent(faqListItemSelector).removeClass(activeClass);
                $(this).attr('aria-expanded', false);
            } else {
                $(faqId).find(faqListItemSelector).removeClass(activeClass);
                $(this).attr('aria-expanded', true);
                $(this).parent(faqListItemSelector).addClass(activeClass);
            }
        })

    });

});
