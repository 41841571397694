/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    if ($('a[data-mfp-src]').length > 0) {

        // If there is a link on the page that links to a magnific popup
        // Check all other links if they're pointing to the same id
        // If so, add the open-popup-link class + right data-mfp-src to that link
        $('a:not([data-mfp-src])').each(function () {

            if (!$(this).attr('href')) {
                return;
            }

            var toggleIndex = $(this).attr('href').indexOf('#toggle');

            if (toggleIndex > -1) {
                var formId = $(this).attr('href').substring(toggleIndex),
                    $element = $(formId);

                if ($element) {
                    $(this).addClass('open-popup-link');
                    $(this).attr('data-mfp-src', formId);
                }
            }
        });
    }

    $('.open-popup-link').magnificPopup({
        type: 'inline'
    });
});
