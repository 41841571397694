/*global window,document,jQuery,Foundation*/
jQuery(document).ready(function ($) {
    'use strict';

    var $rows = $('.js-flexRow');

    function equalHeight() {
        $rows.children().height('auto');
        $rows.children().find('.panel').height('auto');

        $rows.each(function () {
            var maxHeight = 0;

            //make all columns equal in height
            $(this).children().each(function () {
                if ($(this).height() > maxHeight) {
                    maxHeight = $(this).height();
                }
            });
            $(this).children().height(maxHeight);
            $(this).children().find('.panel').first().height(maxHeight - 60);
        });
    }

    $(window).on('resize.MaxServ.equalHeightPerson', Foundation.util.throttle(equalHeight, 300));

    // When selecting another form inside a multiFormPanel, recalculate the height of all panels in this form
    $('.multiFormPanel-select').on('change.MaxServ.equalHeightPerson', equalHeight);

    equalHeight();
});
