/*global window,document,jQuery,Math,Date,setTimeout*/
jQuery(document).ready(function ($) {
    'use strict';

    var bbTagCounter = $('.maxtsubb-l-counter'),
        dateCounter = $('.timeCounter');

    if (bbTagCounter.length) {
        // Initialize all bb tag counters on the current page
        bbTagCounter.each(function () {
            $(this).css('width', $(this).outerWidth());
            // Add a class to the bb tags, so it will be recognized as a counter element
            $(this).addClass('timeCounterItem');
            // Visually we want this to start counting from zero, so we manipulate the html on page load and we
            // save the value in a data attribute for our detectElement() function
            $(this).attr('data-counter-max', $(this).text()).text('0');
        });
    }

    // Define this variable only when all counters have been initialized on the current page (after the bb tag check).
    var counter = $('.timeCounterItem');

    if (dateCounter.length) {
        // Calculate values for the timeCounter element if it exists on the current page. This is not the bb tag counter,
        // but a counter that counts up, based on a specific date.
        dateCounter.each(function () {
            var startDate = new Date('1997/12/27 10:00'),
                today = new Date(),
                dayLabel = $('.timeCounter-daysText'),
                attributeDay = dayLabel.attr('data-day'),
                // minutes*seconds*milliseconds
                oneHour = 60 * 60 * 1000,
                // oneHour * hours
                oneDay = oneHour * 24,
                // oneDay * 365.25 (because leap year)
                oneYear = oneDay * 365.25,
                //calculate milliseconds between the two dates and put result in msBetween
                msBetween = Math.round(Math.abs((startDate.getTime() - today.getTime()))),
                //calculate the number of full years in msBetween
                yearsBetween = Math.floor((msBetween / (oneYear))),
                //calculate the number of full days in msBetween after removing the full years
                daysBetween = Math.floor((msBetween - (yearsBetween * oneYear)) / (oneDay)),
                //calculate the number of full hours in msBetween after removing the full years and full days
                hoursBetween = Math.floor((msBetween - (yearsBetween * oneYear) - (daysBetween * oneDay)) / (oneHour));

            if (yearsBetween > 0) {
                $(this).find('.js-timeCounterItem-years').attr('data-counter-max', yearsBetween);
            }
            if (daysBetween > 0) {
                $(this).find('.js-timeCounterItem-days').attr('data-counter-max', daysBetween);
            }
            if (hoursBetween > 0) {
                $(this).find('.js-timeCounterItem-hours').attr('data-counter-max', hoursBetween);
            }
            if (daysBetween === 1) {
                dayLabel.text(attributeDay);
            }
        });
    }

    if (counter.length) {
        // Detect when a counter element is scrolled into view only every 300ms for performance issues
        $(window).on('scroll.MaxServ.detectElement', Foundation.util.throttle(detectElement, 300));
        // Call detectElement to start the animation in case there are countUp items in the viewport after pageload
        detectElement();
    } else {
        // Don't execute the rest of the script when no counter was found
        return false;
    }

    function detectElement() {
        var window_height = $(window).height(),
            window_top_position = $(window).scrollTop(),
            window_bottom_position = (window_top_position + window_height);

        counter.each(function () {
            var element = $(this),
                element_height = element.outerHeight(),
                element_top_position = element.offset().top,
                element_bottom_position = (element_top_position + element_height),
                element_max_count = element.attr('data-counter-max');

            if (element_max_count > 0 && (element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position) && (!element.hasClass('is-fired'))) {
                // Check if this element is visible in the current viewport for the first time and fire the counting only once
                var options = {
                    useEasing: true,
                    separator: '.'
                },
                numArea = new CountUp($(this)[0], 0, element_max_count, 0, 1.5, options);
                element.addClass('is-fired');

                setTimeout(function () {
                    numArea.start();
                }, 200);
            }
        });
    }
});
