/*global window*/
window.setTransformTranslate = function ($element, xValue, yValue, zValue) {
    'use strict';

    if (xValue === undefined) {
        xValue = 0;
    }

    if (yValue === undefined) {
        yValue = 0;
    }

    if (zValue === undefined) {
        zValue = 0;
    }

    $element.css({
        '-o-transform': 'translate3d(' + xValue + ', ' + yValue + ', ' + zValue + ')',
        '-ms-transform': 'translate3d(' + xValue + ', ' + yValue + ', ' + zValue + ')',
        '-webkit-transform': 'translate3d(' + xValue + ', ' + yValue + ', ' + zValue + ')',
        '-moz-transform': 'translate3d(' + xValue + ', ' + yValue + ', ' + zValue + ')',
        'transform': 'translate3d(' + xValue + ', ' + yValue + ', ' + zValue + ')'
    });
};
