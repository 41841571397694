(function () {

    var loadMoreWrapper = document.querySelectorAll(".js-loadMore"),
        toggleBtnWrapper = document.querySelectorAll(".js-loadMoreToggleWrapper");

    function initItemStates() {
        for (var i = 0; i < loadMoreWrapper.length; i++) {
            var currentWrapper = loadMoreWrapper[i],
                items = currentWrapper.querySelectorAll(".js-loadMoreItem"),
                toggleWrapper = currentWrapper.getElementsByClassName("js-loadMoreToggleWrapper")[0];

            if (items.length < 3) {
                toggleWrapper.remove();
            } else {
                for (var it = 0; it < items.length; it++) {
                    if (it < 3) {
                        items[it].classList.remove("hide");
                    } else {
                        items[it].classList.add("hide");
                    }
                }
            }
        }
    }

    initItemStates();

    window.addEventListener("resize", Foundation.util.throttle(function () {
        if (Foundation.MediaQuery.atLeast("xlarge") === false) {
            initItemStates();
        }
    }, 300));

    for (var i = 0; i < toggleBtnWrapper.length; i++) {
        var toggleBtn = toggleBtnWrapper[i].getElementsByClassName("js-loadMoreToggle")[0];

        toggleBtn.addEventListener("click", function (e) {
            e.preventDefault();

            var hiddenItems = this.closest(".js-loadMore").querySelectorAll(".js-loadMoreItem.hide");

            for (var i = 0; i < hiddenItems.length; i++) {
                if (i < 3) {
                    hiddenItems[i].classList.remove("hide");
                }
            }
        });
    }
})();
