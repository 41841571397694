/*global jQuery,document*/
jQuery(document).ready(function ($) {
    'use strict';

    $('.js-expand').on('click',function (e) {
        var summaryText = $(this).parent();
        var fullText = summaryText.siblings('.fullText');

        e.preventDefault();

        fullText.removeClass('hide');
        summaryText.addClass('hide');
    });
});
