/*global window,document,jQuery,Foundation*/
jQuery(document).ready(function ($) {
    'use strict';

    /*Don't use the JS from Powermail extras, because of some custom tuning for KNC*/

    var harmonicaFormNextSection = $('.js-harmonica-next'),
        harmonicaFormSection = $('.HarmonicaForm-section');

    harmonicaFormSection.each(function() {
        $(this).find('[required]').not("[name='tx_powermail_pi1[field][land]'], [data-dac-role='city'], [data-dac-role='street'], :disabled").last().addClass('toggleNextSection');
    });

    harmonicaFormNextSection.on ('click focus',function(e) {
        e.preventDefault();

        $(this).parents('.HarmonicaForm-section').next('.HarmonicaForm-section').slideDown().addClass('is-active');
    });
});
