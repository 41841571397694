/*global jQuery, window, Foundation*/
/*jslint unparam:true*/
jQuery(document).ready(function ($) {
    'use strict';

    var mediaRangeKeys = ['small', 'medium', 'large', 'xlarge', 'menuSwitch', 'xxlarge'];

    function setLabel(newMediaQuery) {
        $('input[data-dynamic-placeholder]').attr('placeholder', function () {
            var $element = $(this),
                placeholderValue = null,
                rangeIndex = mediaRangeKeys.indexOf(newMediaQuery),
                iterator;

            // set the default placeholder as replacement for small screens
            if ($element.attr('data-dynamic-placeholder-small') === undefined) {
                $element.attr('data-dynamic-placeholder-small', $element.attr('placeholder'));
            }

            if (rangeIndex !== -1) {
                // walk trough the available media ranges back from the given point
                // effectively this means a placeholder for medium also shows up on xlarge
                for (iterator = rangeIndex; iterator >= 0; iterator = iterator - 1) {
                    // if the value is already set don't overwrite it
                    if (placeholderValue === null || placeholderValue === undefined) {
                        placeholderValue = $element.attr('data-dynamic-placeholder-' + mediaRangeKeys[iterator]);
                    }
                }
            }

            return placeholderValue;
        });
    }

    setLabel(Foundation.MediaQuery.current);

    /*jslint unparam: true*/
    $(window).on('changed.zf.mediaquery', function (event, newMediaQuery) {
        setLabel(newMediaQuery);
    });
    /*jslint unparam: false*/
});
