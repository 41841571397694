/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    $('.js-hasRandomNumber').each(function () {
        var lowestValue = parseInt($(this).attr('data-lowestValue'), 10),
            highestValue = parseInt($(this).attr('data-highestValue'), 10),
            randomNumber = Math.floor(Math.random() * (highestValue - lowestValue + 1) + lowestValue);

        $(this).prepend(randomNumber);
    });
});
