/*global jQuery, Pikaday, window*/
/*jslint nomen: true*/

// Use the init event dispatched by parsley before initializing the datepicker UI
// Custom tweaks for KNC:
// - Show today button
// - Week starts on Monday instead of Sunday
// - Input field date is not hidden
// - Localized translation of the selected date
window.Parsley.on('form:init', function (parsleyForm) {
    'use strict';

    parsleyForm.$element.find('[type="date"]').each(function () {
        var $container = jQuery('<div />'),
            defaultValue = new Date(),
            $input = jQuery(this),
            inputId = $input.attr('id'),
            dayLabels = $input.data('datepicker-days'),
            monthLabels = $input.data('datepicker-months'),
            options = {
                // Custom for KNC: week starts with Monday, today button enabled in the date picker
                firstDay: 1,
                todayButton: true
            },
            picker,
            queryParts,
            $parse = function (date, format) {
                // dateString is the result of `toString` method
                var Format = 'd-m-Y';
                if(Format === (format)) {
                    var parts = date.split('-');
                    var day = parseInt(parts[0], 10),
                        month = parseInt(parts[1] - 1, 10),
                        year = parseInt(parts[2], 10);
                    return new Date(year, month, day);
                } else {
                    return new Date(Date.parse(date));
                }
            };

        $container.insertAfter($input);
        $container.addClass('datepicker-container');

        options.container = $container[0];
        options.field = $input[0];
        options.parse = $parse;

        // force the input to type=text to disable UA specific rendering of a datepicker
        $input.prop('type', 'text');

        // display the datepicker in a separate container
        if ($input.data('datepicker-force')) {
            // Custom for KNC: remove input type hidden on date field
            options.bound = false;
        }

        if (monthLabels && dayLabels) {
            options.i18n = {
                // Custom for KNC: get local language label for "Today"
                today : $input.data('datepicker-today'),
                previousMonth : 'Previous Month',
                nextMonth : 'Next Month',
                months: monthLabels.split(','),
                weekdays : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                weekdaysShort: dayLabels.split(',')
            };

            // check if the split character is used, this could indicate optional long labels are set
            if (dayLabels.indexOf('|') !== -1) {
                jQuery.each(dayLabels.split(','), function (index, label) {
                    if (label.indexOf('|') !== -1) {
                        var parts = label.split('|');

                        options.i18n.weekdaysShort[index] = parts[0];
                        options.i18n.weekdays[index] = parts[1];
                    }
                });
            }
        }

        if ($input.data('datepicker-min')) {
            try {
                options.minDate = new Date($input.data('datepicker-min'));
            } catch (ignore) {
                //
            }
        }

        if ($input.data('datepicker-max')) {
            try {
                options.maxDate = new Date($input.data('datepicker-max'));
            } catch (ignore) {
                //
            }
        }

        if (options.minDate && options.maxDate) {
            if (options.minDate.getFullYear() <= options.maxDate.getFullYear()) {
                options.yearRange = [options.minDate.getFullYear(), options.maxDate.getFullYear()];
            }
        }

        if ($input.data('datepicker-format')) {
            options.format = $input.data('datepicker-format');
        }

        options.onSelect = function (selectedDate) {
            var formattedDate = selectedDate.toString(),
                formatCharacters = {
                    'd': selectedDate.getDate().toString(),
                    'm': (selectedDate.getMonth() + 1).toString(),
                    'Y': selectedDate.getFullYear().toString()
                };

            // prefix "d" with a leading zero if the character count is just one
            // see http://php.net/manual/en/function.date.php
            if (formatCharacters.d.length === 1) {
                formatCharacters.d = '0' + formatCharacters.d;
            }

            // "m" should also be prefixed with a leading zero
            if (formatCharacters.m.length === 1) {
                formatCharacters.m = '0' + formatCharacters.m;
            }

            // only modify the # segment of the URL if it's empty
            // or (initially) not today
            if ((window.location.hash === '' && options.minDate !== undefined && options.minDate.toDateString() !== selectedDate.toDateString())
                || window.location.hash.search(inputId) !== -1
            ) {
                window.location.hash = inputId + '=' + selectedDate.getTime();
            }

            if (picker !== undefined && picker !== null && picker._o !== undefined && picker._o !== null && picker._o.format !== undefined) {
                formattedDate = picker._o.format;

                jQuery.each(formatCharacters, function (character, replacement) {
                    formattedDate = formattedDate.replace(
                        new RegExp(character, 'g'),
                        replacement
                    );
                });

                $input.val(formattedDate);

                // Custom for KNC: We can't change the input field value to something like "Thursday 16 March 2023", because this will cause an empty or invalid value in Powermail
                // However we do want to show this localized text on screen.
                var localTime = new Intl.DateTimeFormat('nl-NL', { dateStyle: 'full', timeStyle: 'short'}).format(selectedDate.getTime());

                // Custom for KNC: render today button in the front end.
                $input.parent().find('.pika-chosen').remove();
                $input.after('<span class="pika-chosen">' + localTime.split('om')[0] + '</span>');
            }
        };

        if (window.location.hash && window.location.hash.indexOf(inputId) !== -1) {
            queryParts = window.location.hash.match(new RegExp(inputId + '=([0-9]+)'));

            if (queryParts && queryParts[1]) {
                try {
                    defaultValue.setTime(parseInt(queryParts[1], 10));
                } catch (ignore) {
                    // just ignore any error so the selected date will be today
                }
            }
        }

        picker = new Pikaday(options);

        // force selection of the current date
        // besides setting the correct date this also enforces proper replacement
        picker.setDate(defaultValue);
    });
});
