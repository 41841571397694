/*global jQuery,$.magnificPopup*/

jQuery(function ($) {
    'use strict';

    $('.venueSearchModal').on('MaxServ.Places.Search.Rendered', function () {
        $.magnificPopup.open({
            items: {
                src: '#venueSearchModal'
            },
            type: 'inline',
            showCloseBtn: false,
            alignTop: true,
            mainClass: 'is-light is-offSetTop100',
            fixedContentPos: false,
            callbacks: {
                open: function () {
                    // Cuz Magnific disables page scrolling by default and adds some margin to compensate with the scrollbar,
                    // we over ride these css properties to re enable page scrolling:
                    $('html').css({
                        'overflow': 'auto',
                        'margin-right': 'auto'
                    });
                }
            }
        });
    });
});
