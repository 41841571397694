/*global document,jQuery*/
jQuery(document).ready(function ($) {
    'use strict';

    $('.js-smoothScrollBtn').on('click',function (event) {
        var smoothButtonLink = $(this).attr('href'),
            smoothButtonID = smoothButtonLink.substring(smoothButtonLink.indexOf('#'));

        if (smoothButtonLink.indexOf('#') > -1) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $(smoothButtonID).offset().top
            }, 1000);
        }
    });
});
